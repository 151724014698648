import React from "react"
import style from "./live.mod.scss"
import Block from "@global/layout/Block"

const LiveStream = ({ vimeoId, chatBannerText = "Join the Chat" }) => {
  return (
    <>
      <div className={style.live__stream}>
        <iframe
          title="Live Stream"
          src={`https://vimeo.com/event/${vimeoId}/embed`}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <Block maxWidth="inner">
        <div className={style.live__chat}>
          <iframe
            title="Live Stream Live"
            src={`https://vimeo.com/event/${vimeoId}/chat/`}
            width="100%"
            height="100%"
            frameBorder="0"
          ></iframe>
          <div className={style.live__ribbon}>
            <Block maxWidth="inner">
              <p>{chatBannerText}</p>
            </Block>
          </div>
        </div>
      </Block>
    </>
  )
}

export default LiveStream
