import React from "react"
import { graphql } from "gatsby"
import Content from "@elements/Content"
import Block from "@global/layout/Block"
import Layout from "@layouts/layout"
import { HelmetDatoCms } from "gatsby-source-datocms"
import LiveStream from "@workout/LiveStream"

const Live = ({ data, pageContext: { title, parent } }) => {
  const { streams, notesNode, liveStreamId } = data.liveStream

  return (
    <Layout title={title} parent={parent}>
      <HelmetDatoCms
        defer={false}
        title={`${title}${data.seo.globalSeo.titleSuffix}`}
      />
      <div>
        <LiveStream vimeoId={liveStreamId} />
      </div>
      <Block padding="both" gutters={true} maxWidth="inner">
        <h1>{data.content.live.heading}</h1>
        <Block padding="both">
          <h2>{data.content.live.schedule}</h2>
          <ul>
            {streams.map(stream => {
              return (
                <li key={stream.id}>
                  <strong>
                    {stream.day} {stream.time} - {stream.duration}min
                  </strong>
                  {stream.descriptionNode && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: stream.descriptionNode.childMarkdownRemark.html
                      }}
                    />
                  )}
                </li>
              )
            })}
          </ul>
          <Block padding="top">
            <h2>{data.content.live.notes}</h2>
            <Content html={notesNode.childMarkdownRemark.html} />
          </Block>
        </Block>
      </Block>
    </Layout>
  )
}

export const query = graphql`
  query live {
    liveStream: datoCmsLiveStream {
      id
      liveStreamId
      notesNode {
        childMarkdownRemark {
          html
        }
      }
      streams: liveStreamTimes {
        id
        title
        description
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        time
        day
        duration
        banner {
          gatsbyImageData(imgixParams: { w: "1280" })
        }
      }
    }
    content: contentYaml(language: { eq: "en-NZ" }) {
      live {
        heading
        schedule
        notes
      }
    }
    seo: datoCmsSite {
      ...GlobalSeo
    }
  }
`

export default Live
